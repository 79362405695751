<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-breadcrum">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
        <el-breadcrumb-item>内容管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/ticket/list' }">票务</el-breadcrumb-item>
        <el-breadcrumb-item>新增</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="24" class="warp-main">
      <el-form ref="form" :model="form"  :rules="rules" label-width="150px" class="el-form-self">

        <el-row>
          <el-col>
            <el-form-item label="名称:" prop="name">
              <el-input v-model="form.name" auto-complete="off" placeholder="请输入名称" maxlength="50" show-word-limit clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>


        <el-row>
          <el-col>
            <el-form-item label="序号:" prop="numberOrder">
              <el-input v-model="form.numberOrder" auto-complete="off" placeholder="请输入序号" maxlength="5" show-word-limit clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="景点图片:" prop="imageUrl">
              <el-upload
                class="homeBannerImage-uploader"
                :action="uploadFileURL"
                :show-file-list="false"
                :on-success="handleTicketImageSuccess"
                :before-upload="beforeTicketImageUpload">
                <img v-if="imageUrl" :src="imageUrl" class="homeBannerImage">
                <i v-else class="el-icon-plus homeBannerImage-uploader-icon"></i>
                <div slot="tip" class="el-upload__tip">注意：点击 + 上传.jpg, .png后缀图片文件。图片尺寸为720px x 480px。</div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row >
          <el-col>
            <el-form-item label="图片外链:" prop="outerLinkUrl">
              <el-input v-model="form.outerLinkUrl" auto-complete="off" placeholder="请输入图片外链" maxlength="200" show-word-limit clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="form-buttons">
          <el-col>
            <el-form-item class="el-form-item-self">
              <el-button type="primary" @click="$router.go(-1)">返回</el-button>
              <el-button type="primary" @click="onSubmit(1)">保存</el-button>
              <el-button type="primary" @click="onSubmit(0)">发布</el-button>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>


    </el-col>
  </el-row>

</template>
<script>
  import {reqAddTicket} from '../../../api/ticket-api'
  import {uploadFileURL,downloadFileURL} from '../../../api/system-api'

  export default {

    data() {
      return {
        uploadFileURL:uploadFileURL,
        imageUrl: '',
        form: {
          bannerName: '',
          columns:'',
          content: '',
          numberOrder: '',
          isUse:'',
          type: '',
          imageUrl: '',
          outerLinkUrl: '',

        },
          rules: {
              name: [
                  {required: true, message: '请输入名称', trigger: 'blur'},
              ],
              numberOrder: [
                  {pattern: /^\d{1,5}$/, message:'排序只能是整数',trigger: 'blur'},
              ],
              imageUrl: [
                  {required: true, message:'请上传图片',trigger: 'blur'},
              ],
              outerLinkUrl: [
                  {required: false, message: '请输入图片外链', trigger: 'blur'},
              ],
          },
      }
    },
    methods: {

      onSubmit(status) {
        var that = this;
          this.$refs.form.validate((valid) => {
              if (valid) {
                  if(!that.form.imageUrl){
                      that.$message({
                          message: '请输入图片',
                          type: 'warning'
                      });
                    return false;
                  }
                  this.form.isUse=status;
                  let para = Object.assign({}, this.form);
                  reqAddTicket(para).then((res) => {
                      if(res.data.code===1){
                          that.$message({
                              message: '提交成功',
                              type: 'success'
                          });
                          that.$router.push({path:"/ticket/list"});
                      }else {
                          that.$message({
                              message: '提交失败',
                              type: 'error'
                          });
                          that.$router.push({path:"/ticket/list"});
                      }
                  })
              }
          });

      },

      beforeTicketImageUpload(file) {
        const isValidType = (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png');
        const isLt20M = file.size / 1024 / 1024 < 20;

        if (!isValidType) {
          this.$message.error('不支持改类型的文件上传。');
        }
        if (!isLt20M) {
          this.$message.error('上传文件大小不能超过 20MB!');
        }
        return isValidType && isLt20M;
      },
      handleTicketImageSuccess(response) {
        if (response.code === 1) {
          this.form.imageUrl = response.data;
          this.imageUrl=downloadFileURL + response.data + "/";
          this.$refs.form.validateField('imageUrl');
          console.log(this.imageUrl)
        }
      },

    },
    mounted() {
    }
  };
</script>
